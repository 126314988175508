import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  smoothScroll('a[href^="#"]', { offset: 120 })

  $('li ul').parent().addClass('second-level')

  const selector = '.header-nav-list > li.second-level'
  $(selector).on({
    mouseenter: function () {
      $(this).addClass('open')
      $('.header').addClass('open')
    },
    mouseleave: function () {
      $(selector).removeClass('open')
      $('.header').removeClass('open')
    }
  })

  $(document).scroll(function () {
    const scroll = $(this).scrollTop()
    if (scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })
})
